import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import config from '@/utils/config';

Vue.config.productionTip = false;

// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';
// import { Integrations } from '@sentry/tracing';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://a6eb4a6f849e472f9b7af027d0695927@o428739.ingest.sentry.io/5374434',
//     integrations: [new VueIntegration({ Vue, attachProps: true }), new Integrations.BrowserTracing()],
//     environment: process.env.NODE_ENV,
//     tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0,
//   });
// }

import Toasted from 'vue-toasted';
Vue.use(Toasted);

let stripeInterval = setInterval(() => {
  if (window.Stripe && typeof window.Stripe === 'function') {
    Vue.prototype.$stripe = window.Stripe(config.getValue('VUE_APP_STRIPE_PK'));
  }
  clearInterval(stripeInterval);
}, 500);

Vue.prototype.$baseUrl = `${window.location.protocol}//${window.location.host}`;

/** Vue Authenticate */
import VueAxios from 'vue-axios';
import VueAuthenticate from 'vue-authenticate';
import axios from 'axios';

Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: 'https://localhost:3000', // Your API domain
  providers: {
    google: {
      clientId: '30457543548-660vo336hk1pd0jo2ipidbq386jssk0m.apps.googleusercontent.com',
      redirectUri: 'https://localhost:8080/oauth', // Your client app URL
    },
  },
});

/** Filters */

const { DateTime } = require('luxon');
Vue.filter('getDate', passedDate => {
  let dt = null;

  if (typeof passedDate === 'string') {
    dt = DateTime.fromISO(passedDate);
  } else {
    dt = DateTime.fromJSDate(passedDate);
  }

  return dt.toLocaleString(DateTime.DATETIME_MED);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
