<template>
  <div class="kitchen-scene" :style="{ backgroundImage: `url(${kitchenBkg})` }">
    <div class="kitchen-scene-inner">
      <h1>
        Connect to WiFi with
        <em>just a tap</em>
      </h1>

      <div class="callout-container">
        <div class="callout-header">
          <div>Introducing</div>
          <img class="logo-small" src="@/assets/wifi-block-logo.svg" />
        </div>
        <div class="callout-content">
          <img class="inner-wifiblock" src="@/assets/wifiblock-desktop-kitchen.png" />
          <div>
            <p>
              Stop having to tell your friends, family, and guests your WiFi password.
            </p>
            <p>
              With a WiFi Block, your guests can just tap their phone and be instantly connected to your Wifi -- no
              password required
            </p>
          </div>
        </div>

        <div class="cta-container">
          <div>
            <div class="price">$39.99</div>
            <div class="free-shipping">Free Shipping!</div>
          </div>
          <button @click="gotoDesign">Design your own</button>
        </div>
      </div>

      <img class="our-family-wifi-block" src="@/assets/wifiblock-desktop-kitchen.png" />

      <img class="made-in-usa" src="@/assets/madeinusa.png" />
    </div>
  </div>
</template>

<script>
import kitchenBkg from '@/assets/kitchen-bkg.jpg';

export default {
  name: 'KitchenScene',
  data() {
    return {
      kitchenBkg,
    };
  },
  methods: {
    gotoDesign() {
      this.$router.push({ name: 'design', query: { id: 'new' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.kitchen-scene {
  width: 100%;
  height: 960px;
  background-size: cover;
  background-repeat: no-repeat;

  .kitchen-scene-inner {
    max-width: 2000px;
    height: 960px;
    margin: 0 auto;
    position: relative;

    .price {
      font-size: 28px;
      font-weight: 400;
    }

    h1 {
      /* Connect to Wifi with */
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      margin-top: 60px;
      line-height: 49px;
      color: #ffffff;
      text-shadow: 0px 0px 20px #000000;
    }

    .our-family-wifi-block {
      position: absolute;
      left: 200px;
      top: 300px;

      @media (max-width: 1100px) {
        display: none;
      }
    }

    .callout-container {
      position: absolute;
      width: 435px;
      height: 418px;
      right: 15%;
      background: rgba(107, 171, 192, 0.9);
      border-radius: 20px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      padding: 32px 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;

      .callout-content {
        flex-grow: 1;
        margin-top: 20px;

        .inner-wifiblock {
          display: none;
        }
      }

      button {
        align-self: center;
      }

      .logo-small {
        max-height: 75px;
      }

      .callout-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        /* identical to box height */
        text-align: center;

        color: #ffffff;
      }

      p {
        width: 80%;
        flex-grow: 1;
      }

      .cta-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: right;
        line-height: 28px;

        .free-shipping {
          font-size: 16px;
        }

        button {
          margin-left: 20px;
        }
      }

      @media (max-width: 1100px) {
        width: 550px;

        .callout-content {
          display: flex;
          flex-direction: row;

          .inner-wifiblock {
            display: initial;
            margin-right: 30px;
          }
        }
      }
    }

    .made-in-usa {
      position: absolute;
      bottom: -160px;
      left: 145px;
    }
  }
}
</style>
