var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_vm._m(0),_c('div',{staticClass:"sticky-footer"},[_vm._m(1),_c('div',[_c('router-link',{staticClass:"small",attrs:{"tag":"button","to":"design"}},[_vm._v("Get Started")])],1)]),_vm._m(2),_c('div',{staticClass:"no-more-container"},[_c('h2',[_vm._v("No more having to tell others your wifi password")]),_c('img',{staticClass:"video-callout",attrs:{"src":require("@/assets/wifi-block-maple.png")}}),_vm._m(3),_c('router-link',{attrs:{"tag":"button","to":"design"}},[_vm._v("Get started designing your own")])],1),_vm._m(4),_vm._m(5),_vm._m(6),_c('FAQMobile'),_c('div',{staticClass:"padded-container"},[_c('router-link',{attrs:{"tag":"button","to":"design"}},[_vm._v("Get started")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/wifi-block-logo.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("$39.99")]),_c('div',{staticClass:"free-shipping"},[_vm._v("Free Shipping!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kitchen-container"},[_c('h1',[_vm._v("Connecting to Wifi has never been easier")]),_c('img',{staticClass:"livepreview",attrs:{"src":require("@/assets/wifiblock-mobile-kitchen.png")}}),_c('div',{staticClass:"padded-container"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" Guests just tap their"),_c('br'),_vm._v(" phone, and they’re connected! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fully-customizable"},[_c('h2',[_vm._v(" Fully Customizable"),_c('br'),_vm._v(" Make it your own ")]),_c('img',{attrs:{"src":require("@/assets/faux-carousel-blocks.png"),"loading":"lazy","srcset":require("@/assets/faux-carousel-blocks@2x.png") + " 2x, " + require("@/assets/faux-carousel-blocks@3x.png") + " 3x"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widely-supported"},[_c('h2',[_vm._v("Widely Supported")]),_c('div',{staticClass:"brands"},[_c('div',{staticClass:"brand"},[_c('img',{attrs:{"src":require("@/assets/apple-logo.svg")}}),_c('div',[_vm._v("iPhone")])]),_c('div',{staticClass:"brand"},[_c('img',{attrs:{"src":require("@/assets/android-logo.svg")}}),_c('div',[_vm._v("Android")])])]),_c('div',{staticClass:"disclaimer"},[_vm._v(" * device must have nfc capability and be connected to the desired wifi for programming ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-container"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":require("@/assets/testimonial-image.png"),"loading":"lazy","srcset":require("@/assets/testimonial-image@2x.png") + " 2x, " + require("@/assets/testimonial-image@3x.png") + " 3x"}})]),_c('div',{staticClass:"quote"},[_vm._v(" Our Wifi Block is such a conversation piece! Everyone is amazed by it and wants one! ")])])
}]

export { render, staticRenderFns }