<template>
  <div class="login-modal-container">
    <div class="veil"></div>

    <div class="login-modal">
      <div class="close-btn" @click="closeLoginModal">+</div>
      <img class="logo" src="@/assets/wifi-block-logo.svg" />
      <h3>Login to continue</h3>
      <div class="subheading">Login to save your design</div>

      <div>
        <label>Email</label>
        <input type="text" placeholder="user@example.com" v-model="email" />
        <button class="small centered" @click="setEmail">Login</button>
      </div>

      <div class="border-word">
        <hr />
        OR
        <hr />
      </div>

      <div class="button-container">
        <GoogleLogin class="social" :params="params" :onSuccess="googleLoginSuccess" :onFailure="googleLoginFailure">
          <img src="@/assets/btn-sign-in-g.svg" />
        </GoogleLogin>

        <img src="@/assets/btn-sign-in-fb.svg" @click="loginFb" />
      </div>
    </div>
  </div>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import params from '@/google_oauth_config.json';
import auth from '@/utils/auth';

export default {
  name: 'LoginModal',
  components: { GoogleLogin },
  data() {
    return {
      email: null,
      params,
    };
  },
  methods: {
    closeLoginModal() {
      this.$store.dispatch('setShowLoginModal', false);
    },
    googleLoginSuccess(user) {
      let email = user.getBasicProfile().getEmail();
      this.$store.dispatch('updateDesign', { email });
      this.$store.dispatch('setShowLoginModal', false);
    },
    googleLoginFailure(err) {
      window.wberr = err;
      // console.log('err: ' + JSON.stringify(err);
    },
    loginFb: auth.loginFb,
    setEmail() {
      this.$store.dispatch('updateDesign', { email: this.email });
      this.$store.dispatch('setShowLoginModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.veil {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.login-modal {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 200;
  padding: 40px 60px;
  color: $tuatara;

  .close-btn {
    color: $tuatara;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 20px;
    font-size: 32px;
    transform: rotate(45deg);
    cursor: pointer;
    transition: all 250ms ease-out;

    &:hover {
      transform: rotate(45deg) scale(1.2);
    }
  }

  .logo {
    height: 50px;
  }

  .subheading {
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 30px;
  }

  label {
    text-align: left;
  }

  input {
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      margin: 10px 5px;
      cursor: pointer;

      &:hover {
        filter: brightness(1.1);
      }
      &:active {
        filter: brightness(0.9);
      }
    }
  }

  button.social {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .border-word {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin: 20px 0;

    hr:first-of-type {
      margin-right: 10px;
    }
    hr:nth-of-type(2) {
      margin-left: 10px;
    }
  }

  .centered {
    margin: 0 auto;
  }
}
</style>
