<template>
  <div class="icon-search-modal">
    <div class="veil"></div>
    <div class="icon-search-modal-inner">
      <div class="close" @click="$emit('close')">+</div>

      <div class="input-group">
        <input
          id="iconSearch"
          type="text"
          v-model="searchTerm"
          @keyup.enter="searchIcon"
          placeholder="dog, cat, family, wine"
        />
        <button @click="searchIcon">search</button>
      </div>

      <div class="search-results">
        <div v-if="searchResults.length === 0 && searchesPerformed > 0" class="none-found">No icons found</div>
        <img
          v-else
          :key="result.id"
          v-for="result in searchResults"
          v-bind:data-style-id="result.style"
          :src="result.png"
          @click="$emit('add-icon', result.svg)"
          :class="{ selected: result.id === selectedIcon.id }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'IconSearchModal',
  data: () => {
    return {
      searchTerm: '',
      searchesPerformed: 0,
      searchResults: [],
      selectedIcon: {},
      mobileMenuActive: null,
    };
  },
  methods: {
    async searchIcon() {
      let resp = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/icons/search?q=${this.searchTerm}`);
      this.$data.searchResults = resp.data;
      this.$data.searchesPerformed++;
    },
    selectIcon(id) {
      this.$data.selectedIcon = this.searchResults.filter(e => e.id === id)[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-search-modal {
  .veil {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .icon-search-modal-inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    max-height: 75%;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: $tuatara;
      // color: white;
      // background-color: $tuatara;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      // border: 1px solid black;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      padding: 10px;
      transform: rotate(45deg);
      transition: transform 150ms ease-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.2) rotate(45deg);
      }
    }

    .search-results {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      // max-height: 200px;
      max-height: 85%;
      min-width: 90%;
      width: 100%;
      overflow: scroll;
      align-self: flex-start;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      transition: height 500ms ease-out;

      .none-found {
        grid-column: 1 / -1;
      }

      div {
        grid-column: 2fr;
      }

      img {
        margin: 14px auto;

        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;

        background-color: white;
        border-radius: 6px;
        padding: 6px;
        box-sizing: border-box;
        cursor: pointer;
        border: 2px solid #b5b5b5;
        transition: transform 150ms ease-out;

        &.selected {
          border: 2px solid $tulip-tree;
        }

        &:hover {
          background-color: #eeeeee;
          transform: scale(2);
        }
      }
    }
  }

  @include responsive(mobile) {
    .icon-search-modal-inner {
      border-radius: 0;
      width: 90%;
      box-sizing: border-box;
      padding-top: 60px;

      .search-results {
        max-width: 100%;
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
</style>
