<template>
  <div
    class="mobile-header"
    v-if="$store.state.layout === 'mobile' && ($route.meta.header === undefined || $route.meta.header === true)"
  >
    <img class="logo" @click="$router.push('/')" src="@/assets/wifi-block-logo.svg" />
  </div>
</template>

<script>
export default {
  name: 'MobileHeader',
};
</script>

<style lang="scss" scoped>
.mobile-header {
  background-color: white;
  color: $tuatara;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 5%;
  box-sizing: border-box;

  img {
    height: 47px;
    cursor: pointer;
  }
}
</style>
