export default {
  getValue: val => {
    //@ts-ignore
    if (window.appconfig && window.appconfig[val]) {
      //@ts-ignore
      return window.appconfig[val];
    }

    return process.env[val];
  },
};
