<template>
  <div class="render-container">
    <LivePreview :text1="text1" :iconUrl="iconUrl" :woodType="woodType" />
  </div>
</template>

<script>
import LivePreview from '@/components/LivePreview';

export default {
  name: 'RenderView',
  components: { LivePreview },
  data() {
    return {
      text1: '',
      iconUrl: '',
      woodType: '',
    };
  },
  mounted() {
    const text1 = this.$route.query.text1;
    const iconUrl = this.$route.query.iconUrl;
    const woodType = this.$route.query.woodType;

    this.text1 = text1;
    this.iconUrl = iconUrl;
    this.woodType = woodType;
  },
  methods: {},
};
</script>
