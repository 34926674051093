import axios from 'axios';
import store from '@/store';
import analytics from '@/utils/analytics';
import auth from '@/utils/auth';
import Vue from 'vue';
import config from '@/utils/config';

export default {
  async payWithStripeCheckout() {
    analytics.track('click', 'design', 'add-to-cart');

    // Check for login already
    if (store.getters.design.email) {
      store.dispatch('setShowLoginModal', false);
      store.dispatch('updateDesign', { email: store.getters.design.email });
    } else {
      let email = await auth.areLoggedIn();
      if (email) {
        store.dispatch('updateDesign', { email });
      } else {
        store.dispatch('setShowLoginModal', true);
        store.dispatch('setCheckoutAfterLogin', true);
        return;
      }
    }

    store.dispatch('setFullPageLoader', true);

    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    let images = [store.state.previewImage];

    let sessionResp = await axios.post(`${config.getValue('VUE_APP_BACKEND_HOST')}/payment/begin`, {
      base_url: baseUrl,
      design_id: store.state.design._id,
      visitor_id: store.state.visitor_id,
      images,
    });
    localStorage.setItem('stripeSession', JSON.stringify(sessionResp.data));

    if (window.fbq) {
      window.fbq('track', 'AddToCart', {
        value: 39.99,
        currency: 'USD',
      });
    }
    if (window.rdt) {
      window.rdt('track', 'AddToCart');
    }

    analytics.track('navigation', 'checkout', 'load');
    store.dispatch('setFullPageLoader', false);
    let sessionId = sessionResp.data.id;

    if (sessionId) {
      Vue.prototype.$stripe.redirectToCheckout({
        sessionId: sessionId,
      });
    }
  },
};
