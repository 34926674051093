<template>
  <div
    id="wifi-block"
    :style="{
      transform: scale ? `scale(${scale})` : '',
    }"
  >
    <transition-group name="fade">
      <img key="oak" v-show="woodType === 'oak'" class="wood-mask" src="@/assets/oak-mask.png" rel="preload" />
      <img key="maple" v-show="woodType === 'maple'" class="wood-mask" src="@/assets/maple-mask.png" rel="preload" />
      <img key="poplar" v-show="woodType === 'poplar'" class="wood-mask" src="@/assets/poplar-mask.png" rel="preload" />
    </transition-group>

    <div ref="text" @click="$emit('text-clicked')" class="text-preview" :style="getFontStyle">
      {{ text1 }}
    </div>

    <div @click="$emit('icon-clicked')" class="icon-preview">
      <img ref="icon1" v-if="iconUrl" :src="iconUrl || require('@/assets/wifi-icon.svg')" />
      <img ref="icon1" v-else src="@/assets/wifi-icon.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LivePreview',
  props: ['scale', 'text1', 'iconUrl', 'woodType'],
  computed: {
    ...mapGetters(['design']),
    getFontStyle() {
      let textSize = this.text1.length;

      if (textSize < 12) {
        return {
          fontSize: '110px',
          lineHeight: '100px',
        };
      }

      if (textSize < 30) {
        return {
          fontSize: '80px',
          lineHeight: '95px',
        };
      }

      return {
        fontSize: '60px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#wifi-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 390px;
  height: 665px;
  max-height: 100%;
  user-select: none;
  -webkit-user-drag: none;
  background-size: cover;
  color: black;
  font-weight: 600;
  word-wrap: break-word;
  transition: transform 250ms ease-out;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform-origin: top center;

  // @media only screen and (max-width: 1000px) {
  //   transform: scale(0.9);
  // }

  // @media only screen and (max-height: 800px) {
  //   transform: scale(0.8);
  // }

  #name1 {
    max-height: 100px;
    max-width: 367px;
  }

  #wifi-block-icon {
    flex-direction: column;
    justify-self: flex-end;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-bottom: 115px;
  }

  .text-preview {
    position: absolute;
    top: 50px;
    font-size: 60px;
    // font-family: 'Open Sans', sans-serif;
    // font-family: 'WoodBurn';
    font-family: 'Acme', sans-serif;
    font-weight: 300;
    // color: #3a3a39;
    width: 320px;
    padding-right: 5px;
  }

  .icon-preview {
    position: absolute;
    width: 175px;
    height: 175px;
    bottom: 75px;
    filter: contrast(0.8);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .wood-mask {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
