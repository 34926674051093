<script>
// import axios from 'axios';
// import analytics from '@/utils/analytics';
import auth from '@/utils/auth';
import checkout from '@/utils/checkout';

export default {
  data() {
    return {
      unsubscribe: null,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (
        this.$store.state.checkoutAfterLogin &&
        mutation.type === 'setDesign' &&
        (mutation.payload && mutation.payload.email)
      ) {
        this.$store.dispatch('setCheckoutAfterLogin', false);
        checkout.payWithStripeCheckout();
      }
    });
  },
  async mounted() {
    let email = await auth.areLoggedIn();
    if (email) {
      this.$store.dispatch('updateDesign', { email });
    } else {
      this.$store.dispatch('updateDesign', {});
    }

    // mouseflow tagging
    if (window._mfq) {
      window._mfq.push(['tag', 'saw-design']);
    }
  },
  methods: {
    saveDesign() {},
  },
};
</script>
