<template>
  <div
    class="header"
    v-if="$store.state.layout !== 'mobile' && ($route.meta.header === undefined || $route.meta.header === true)"
  >
    <img class="logo" @click="$router.push('/')" src="@/assets/wifi-block-logo.svg" />
    <div class="right-nav">
      <router-link tag="button" to="setup">Setup Your Wifi Block</router-link>
      <a target="_blank" href="mailto:info@thewifiblock.com">Contact us</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  color: $tuatara;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  box-sizing: border-box;

  img {
    height: 67px;
    cursor: pointer;
  }

  .right-nav {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    a,
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $tuatara;
      text-decoration: none;
      background-color: transparent;
      border: none;
      line-height: 16px;
      box-shadow: none;
      border-radius: 0;
      font-size: 16px;
      padding: 0px 10px;
      margin: 0 10px;
      height: 30px;
    }
  }
}
</style>
