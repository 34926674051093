<template>
  <div class="debugbar">
    <button @click="clearCache">clear cache</button>
    <button @click="googleLogout">google logout</button>
    <button @click="fbLogout">facebook logout</button>
  </div>
</template>

<script>
export default {
  name: 'DebugBar',
  methods: {
    clearCache() {
      localStorage.clear();
      window.location.reload();
    },
    googleLogout() {
      var auth2 = window.gapi.auth2.getAuthInstance();

      if (auth2) {
        auth2.signOut().then(function() {
          auth2.disconnect();
        });
      }
    },
    fbLogout() {
      window.FB.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.debugbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  button {
    font-size: 12px;
    height: auto;
  }
}
</style>
