<template>
  <div class="main-content">
    <div class="header">
      <img class="logo" src="@/assets/wifi-block-logo.svg" />
    </div>

    <div class="sticky-footer">
      <div>
        <div>$39.99</div>
        <div class="free-shipping">Free Shipping!</div>
      </div>

      <div><router-link tag="button" class="small" to="design">Get Started</router-link></div>
    </div>

    <div class="kitchen-container">
      <h1>Connecting to Wifi has never been easier</h1>
      <img class="livepreview" src="@/assets/wifiblock-mobile-kitchen.png" />

      <div class="padded-container"></div>
    </div>

    <div class="no-more-container">
      <h2>No more having to tell others your wifi password</h2>
      <img class="video-callout" src="@/assets/wifi-block-maple.png" />
      <h2>
        Guests just tap their<br />
        phone, and they’re connected!
      </h2>

      <router-link tag="button" to="design">Get started designing your own</router-link>
    </div>

    <div class="fully-customizable">
      <h2>
        Fully Customizable<br />
        Make it your own
      </h2>

      <img
        src="@/assets/faux-carousel-blocks.png"
        loading="lazy"
        srcset="@/assets/faux-carousel-blocks@2x.png 2x, @/assets/faux-carousel-blocks@3x.png 3x"
      />
    </div>

    <div class="widely-supported">
      <h2>Widely Supported</h2>

      <div class="brands">
        <div class="brand">
          <img src="@/assets/apple-logo.svg" />
          <div>iPhone</div>
        </div>
        <div class="brand">
          <img src="@/assets/android-logo.svg" />
          <div>Android</div>
        </div>
      </div>

      <div class="disclaimer">
        * device must have nfc capability and be connected to the desired wifi for programming
      </div>
    </div>

    <div class="testimonial-container">
      <div class="avatar">
        <img
          src="@/assets/testimonial-image.png"
          loading="lazy"
          srcset="@/assets/testimonial-image@2x.png 2x, @/assets/testimonial-image@3x.png 3x"
        />
      </div>

      <div class="quote">
        Our Wifi Block is such a conversation piece! Everyone is amazed by it and wants one!
      </div>
    </div>

    <FAQMobile />

    <div class="padded-container">
      <router-link tag="button" to="design">Get started</router-link>
    </div>
  </div>
</template>

<script>
import FAQMobile from '@/components/FAQMobile';

export default {
  name: 'HomeMobile',
  components: { FAQMobile },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-content {
  background-color: #cbcdcc;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  text-align: left;
  color: #000000;

  .carousel-container {
    background-color: #73acbf;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    button {
      margin: 40px 0;
    }

    .carousel-blocks {
      min-width: 100%;
    }

    .description {
      margin: 0 25px;
      font-size: 18px;

      &:nth-of-type(2) {
        margin-top: 25px;
      }
    }
  }

  .header {
    height: 100px;
    background-color: #ffffff;
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000000;
    padding: 20px 0 0;

    img {
      max-height: 67px;
      margin-top: 5px;
    }
  }

  .made-in-usa {
    width: 170px;
  }

  h3 {
    margin: 0 auto;
  }

  .kitchen-container {
    background: url(../../assets/mobile-kitchen.jpg);
    background-size: cover;
    background-position: center center;
    height: 450px;
    width: 100%;
    position: relative;
    text-align: center;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url(../../assets/mobile-kitchen@3x.jpg);
      background-size: cover;
      background-position: center center;
    }

    button {
      transform: translateY(20px);
    }

    .livepreview {
      transform: translate(-50px, -50px);
    }
  }

  .no-more-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 42px 40px;

    // .video-callout {
    // box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
    // }
  }

  .fully-customizable {
    background-color: #3a3a39;
    padding: 30px 0 100px;
    color: white;
    line-height: 27px;

    img {
      width: 100%;
    }
  }

  .widely-supported {
    background-color: white;

    .brands {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 33px;
      color: #000000;

      img {
        margin-bottom: 14px;
      }
    }

    .disclaimer {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #000000;
      margin: 30px 17px;
    }
  }

  .testimonial-container {
    background-color: #3a3a39;
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    padding: 60px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .quote {
      font-family: Courier New;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      color: #ffffff;
      text-align: center;
      width: 300px;
      margin-top: 25px;

      img:nth-of-type(2) {
        margin-left: auto;
      }
    }
  }

  .padded-container {
    box-sizing: border-box;
    width: 100%;
    padding: 0 42px;
  }

  button {
    width: 100%;
  }

  .sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $tuatara-50;
    font-size: 18px;
    z-index: 100;

    & > div:first-of-type {
      margin-right: 20px;
      text-align: right;
    }

    .free-shipping {
      font-size: 12px;
    }
  }
}
</style>
