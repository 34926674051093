import axios from 'axios';
import store from '@/store';

export default {
  track: (type, name, value) => {
    let data = {
      app: `wifiblock-${process.env.NODE_ENV}`,
      visitor_id: store.getters.visitor_id,
      session_id: null,
      user_id: store.getters.design ? store.getters.design.email : null,
      event_type: type,
      event_name: name,
      event_value: value,
      url: window.location.href,
      referer: document.referrer,
      useragent: navigator.userAgent,
    };

    if (process.env.NODE_ENV === 'production') {
      axios.post('https://tail.jonsapps.com/track', data);
    } else {
      //eslint-disable-next-line no-console
      console.log('analytics call: ' + JSON.stringify(data));
    }
  },
};
