<template>
  <div class="faq-container">
    <h2>Frequently Asked Questions</h2>

    <div class="faq-questions-parent">
      <div class="faq-questions">
        <div :key="faq.question" v-for="faq in faqData">
          <div class="title">{{ faq.question }}</div>
          <div class="description">{{ faq.answer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import faqData from '@/faq.json';

export default {
  name: 'FAQDesktop',
  data() {
    return {
      faqData,
    };
  },
  methods: {
    expand(question) {
      let faqData = this.faqData;
      let q = faqData.filter(e => e.question === question)[0];
      q.expanded = !q.expanded;
      Vue.set(this.faqData, faqData);
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  box-sizing: border-box;

  h2 {
    color: #000000;
    margin-bottom: 40px;
    font-size: 36px;
  }

  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 15px;

    img {
      width: 15px;
      transition: 250ms ease-in;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-top: 6px;
    text-align: left;
  }

  .faq-questions-parent {
    margin: 0 auto;
  }
  .faq-questions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    justify-content: center;
    width: 1200px;

    & > div {
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      padding-bottom: 40px;
    }
  }
}
</style>
