import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import config from '@/utils/config';

var QRCode = require('qrcode-svg');

Vue.use(Vuex);
import adminModule from './adminModule';

/** Logrocket setup */
import LogRocket from 'logrocket';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('yf9neh/wifi-block');
}

import createPlugin from 'logrocket-vuex';
const logrocketPlugin = createPlugin(LogRocket);

function getCurrentLayout() {
  if (window.outerWidth < 700) {
    return 'mobile';
    // } else if (window.innerWidth < 1200) {
    //   return 'tablet';
  }
  return 'desktop';
}

export default new Vuex.Store({
  plugins: [logrocketPlugin],
  modules: { admin: adminModule },
  state: {
    layout: getCurrentLayout(),
    debugMode: false,
    visitor_id: '',
    fullpageLoader: false,
    showLoginModal: false,
    previewImage: null,
    templates: [],
    design: {
      _id: null,
      email: null,
      wood_type: 'maple',
      fabric_data: null,
      stage: 'editable',
    },
    appLinks: {
      android: {
        link: 'https://play.google.com/store/apps/details?id=com.nxp.nfc.tagwriter&hl=en_US',
        qr: new QRCode('https://thewifiblock.com/ios').svg(),
      },
      ios: {
        link: 'https://apps.apple.com/us/app/nfc-tools/id1252962749',
        qr: new QRCode('https://thewifiblock.com/android').svg(),
      },
    },
    fontsLoaded: false,
    checkoutAfterLogin: false,
  },
  mutations: {
    setLayout(state, val) {
      state.layout = val;
    },
    setVisitorId(state, val) {
      state.visitor_id = val;
    },
    setDesign(state, val) {
      state.design = val;

      localStorage.setItem('design', JSON.stringify(val));
    },
    setFullPageLoader(state, val) {
      state.fullpageLoader = val;
    },
    setShowLoginModal(state, val) {
      state.showLoginModal = val;
    },
    setDebugMode(state, val) {
      state.debugMode = val;
    },
    setFontsLoaded(state, val) {
      state.fontsLoaded = val;
    },
    setCheckoutAfterLogin(state, val) {
      state.checkoutAfterLogin = val;
    },
    setPreviewImage(state, val) {
      state.previewImage = val;
    },
    setTemplates(state, val) {
      state.templates = val;
    },
  },
  actions: {
    updateLayout: ({ commit, state }) => {
      let layout = getCurrentLayout();

      if (layout !== state.layout) {
        commit('setLayout', layout);
      }
    },
    setVisitorId: ({ commit }, val) => {
      commit('setVisitorId', val);

      LogRocket.identify(val, {
        // name: 'James Morrison',
        // email: 'jamesmorrison@example.com',
      });
    },
    setDesign: ({ commit }, val) => {
      commit('setDesign', val);
    },
    updateDesign: async ({ commit, state }, val) => {
      let designUpdate = { ...state.design, ...val, visitor_id: state.visitor_id };
      commit('setDesign', designUpdate);

      let designResp = await axios.post(`${config.getValue('VUE_APP_BACKEND_HOST')}/design`, designUpdate);

      commit('setDesign', designResp.data);
    },
    setFullPageLoader: ({ commit }, val) => {
      commit('setFullPageLoader', val);
    },
    setShowLoginModal: ({ commit }, val) => {
      commit('setShowLoginModal', val);
    },
    setDebugMode: ({ commit }, val) => {
      commit('setDebugMode', val);
    },
    setFontsLoaded: ({ commit }, val) => {
      commit('setFontsLoaded', val);
    },
    setCheckoutAfterLogin: ({ commit }, val) => {
      commit('setCheckoutAfterLogin', val);
    },
    setPreviewImage: ({ commit }, val) => {
      commit('setPreviewImage', val);
    },
    loadTemplates: async ({ commit }) => {
      let resp = await axios.get(`${config.getValue('VUE_APP_BACKEND_HOST')}/templates`);
      commit('setTemplates', resp.data);
    },
  },
  getters: {
    visitor_id: state => state.visitor_id,
    design: state => state.design,
    appLinks: state => state.appLinks,
    showLoginModal: state => state.showLoginModal,
    isMobile: state => {
      return state.layout === 'mobile';
    },
  },
});
