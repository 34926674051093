<template>
  <div style="height: 100%;">
    <DesignNew />
    <!-- <DesignDesktop v-if="$store.state.layout === 'desktop'" />
    <DesignMobile v-if="$store.state.layout === 'mobile'" /> -->
  </div>
</template>

<script>
// import DesignDesktop from './DesignDesktop';
// import DesignMobile from './DesignMobile';
import DesignNew from './DesignNew';

export default {
  name: 'DesignIndex',
  components: { DesignNew },
};
</script>
