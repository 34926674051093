import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home/Home.vue';
import Design from './views/Design/Design.vue';
import Render from '@/views/Render.vue';

import analytics from '@/utils/analytics';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        footer: true,
      },
    },
    {
      path: '/design',
      name: 'design',
      component: Design,
      meta: {
        showDesktopHeader: true,
        showMobileHeader: true,
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('./views/Checkout.vue'),
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('./views/Success.vue'),
    },
    {
      path: '/render',
      name: 'render',
      component: Render,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/PrivacyPolicy'),
    },
    {
      path: '/admin',
      component: () => import('@/views/Admin/AdminLayout'),
      children: [
        {
          path: '',
          name: 'admin',
          component: () => import('@/views/Admin/Dashboard/Dashboard'),
        },
        {
          path: 'order/:id',
          name: 'adminOrder',
          component: () => import('@/views/Admin/Order/Order'),
        },
        {
          path: 'designs',
          name: 'adminDesigns',
          component: () => import('@/views/Admin/Designs/Designs'),
        },
        {
          path: 'templates',
          name: 'adminTemplates',
          component: () => import('@/views/Admin/Templates/Templates'),
        },
        {
          path: 'templates/:id',
          name: 'adminTemplateDesign',
          component: () => import('@/views/Admin/Templates/Designer'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login'),
    },
    {
      path: '/setup',
      name: 'setup',
      component: () => import('@/views/Setup/Setup'),
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  analytics.track('navigation', to.name, 'load');
  next();
});

export default router;
