import params from '@/google_oauth_config.json';
import store from '@/store';

export default {
  areLoggedIn: async function() {
    // Try facebook first
    let email = await this.fbIsLoggedIn();

    if (!email) {
      email = await this.googleIsLoggedIn();
    }

    return email;
  },
  fbIsLoggedIn: () => {
    return new Promise(resolve => {
      let interval = setInterval(async () => {
        let url = '/me?fields=name,email';

        if (window.FB && window.FB.api) {
          window.FB.api(url, response => {
            if (response.email) {
              resolve(response.email);
            }
          });

          resolve(null);
          clearInterval(interval);
        }
      }, 500);
    });
  },
  googleAuthInit: async () => {
    let interval = setInterval(async () => {
      if (window.gapi && window.gapi.auth2 && window.gapi.auth2.init) {
        await window.gapi.auth2.init(params);
        clearInterval(interval);
      }
    }, 500);
  },
  googleIsLoggedIn: async () => {
    let email = null;

    try {
      email = window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getBasicProfile()
        .getEmail();
    } catch (ex) {
      // console.log('no email from google');
    }

    return email;
  },
  loginFb: () => {
    window.FB.login(
      response => {
        if (response.status === 'connected') {
          var url = '/me?fields=name,email';
          window.FB.api(url, response => {
            // console.log('name: ' + response.name);
            // console.log('email: ' + response.email);

            store.dispatch('updateDesign', { email: response.email });
            store.dispatch('setShowLoginModal', false);
          });
        } else {
          // unsuccessful
        }
      },
      { scope: 'email' },
    );
  },
};
