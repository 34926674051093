<template>
  <div>
    <HomeDesktop v-if="$store.state.layout === 'desktop'" />
    <HomeMobile v-else />
  </div>
</template>

<script>
import HomeDesktop from './HomeDesktop';
import HomeMobile from './HomeMobile';

export default {
  name: 'HomeIndex',
  components: { HomeDesktop, HomeMobile },
};
</script>
