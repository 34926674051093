<template>
  <div class="faq-container">
    <h2>Frequently Asked Questions</h2>

    <div class="faq-questions">
      <div @click="expand(faq.question)" :key="faq.question" v-for="faq in faqData">
        <div class="title">
          {{ faq.question }} <img :class="{ expanded: faq.expanded }" src="@/assets/down-arrow.svg" />
        </div>
        <div v-if="faq.expanded" class="description">{{ faq.answer }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import faqData from '@/faq.json';

export default {
  name: 'FAQMobile',
  data() {
    return {
      faqData,
    };
  },
  methods: {
    expand(question) {
      let faqData = this.faqData;
      let q = faqData.filter(e => e.question === question)[0];
      q.expanded = !q.expanded;
      Vue.set(this.faqData, faqData);
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-container {
  padding: 30px;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 10px;
  }

  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin-top: 30px;

    img {
      width: 15px;
      transition: 250ms ease-in;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-top: 6px;
  }
}
</style>
