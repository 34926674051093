<template>
  <div class="main-content">
    <Header />
    <KitchenScene />
    <HowItWorks />

    <h1 class="title">Get started with one of our designs</h1>
    <div class="template-container">
      <div v-bind:key="template._id" v-for="template in $store.state.templates">
        <img :src="template.preview_image" @click="$router.push({ name: 'design', query: { id: template._id } })" />
        <button @click="$router.push({ name: 'design', query: { id: template._id } })">Choose Design</button>
      </div>
    </div>
    <FAQDesktop />

    <!-- <img class="full-image" loading="lazy" src="@/assets/group-of-friends.jpg" /> -->
  </div>
</template>

<script>
import KitchenScene from '@/components/KitchenScene';
import HowItWorks from '@/components/HowItWorks';
import FAQDesktop from '@/components/FAQDesktop';
import Header from '@/components/Header';

export default {
  name: 'HomeDesktop',
  components: { KitchenScene, HowItWorks, FAQDesktop, Header },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-content {
  background-color: #3a3a39;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;

  .full-image {
    width: 75%;
    margin: 0 auto;
  }

  .title {
    text-align: center;
    margin: 0 auto;
    margin-top: 80px;
  }
  .template-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 80px;
    row-gap: 60px;
    column-gap: 60px;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover{
        img{
          transform: scale(1.05);
        }
      } 
    }

    img {
      cursor: pointer;
      height: 300px;
      transition: transform 250ms ease-out;
    }

    button {
      font-size: 15px;
      height: auto;
      padding: 5px 10px;
      margin-top: 10px;
    }
  }
}
</style>
