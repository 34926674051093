<template>
  <div class="designer-container">
    <div class="left">
      <div class="button-group">
        <button @click="addText">Add Text</button>
        <button @click="setShowImageModal">Add Image</button>
      </div>

      <div class="swatches row">
        <div class="column swatch" @click="updateWood('poplar')">
          <img src="@/assets/poplar-swatch.png" />
          <div>Poplar</div>
        </div>
        <div class="column swatch" @click="updateWood('maple')">
          <img src="@/assets/maple-swatch.png" />
          <div>Maple</div>
        </div>
        <div class="column swatch" @click="updateWood('oak')">
          <img src="@/assets/oak-swatch.png" />
          <div>Oak</div>
        </div>
        <div class="column swatch" @click="updateWood('purpleheart')">
          <img src="@/assets/purpleheart-swatch.png" />
          <div>Purpleheart</div>
        </div>
      </div>

      <div class="button-group">
        <button class="secondary">Go back</button>
        <button class="sold-out" @click="checkout">Sold Out</button>
      </div>
    </div>
    <div class="right">
      <div class="top-toolbar">
        <div
          class="font-container"
          :class="{ enabled: activeObj && activeObj.type == 'textbox' }"
          @click="toggleFontDropdown"
        >
          {{ activeObj && activeObj.type === 'textbox' ? activeObj.text.slice(0, 7) : '       ' }}
          <img v-if="activeObj && activeObj.type === 'textbox'" src="@/assets/arrow.svg" />

          <div class="font-dropdown" v-if="showFontDropdown">
            <div
              :style="{ fontFamily: font.family, fontWeight: font.weight }"
              @click="fontFamily(font)"
              :key="font.family"
              v-for="font in fonts"
            >
              {{ activeObj && activeObj.type === 'textbox' ? activeObj.text.slice(0, 8) : '' }}
            </div>
          </div>
        </div>

        <div class="button-group">
          <img alt="left align" title="left align" @click="align('left')" src="@/assets/toolbar/left-align.svg" />
          <img
            alt="center align"
            title="center align"
            @click="align('center')"
            src="@/assets/toolbar/center-align.svg"
          />
          <img alt="right align" title="right align" @click="align('right')" src="@/assets/toolbar/right-align.svg" />
        </div>

        <div class="button-group">
          <button title="size down" @click="textSize('down')">-</button>
          <button title="size up" @click="textSize('up')">+</button>
        </div>

        <img title="undo" alt="undo" @click="undo" src="@/assets/toolbar/undo.svg" />
        <img title="remove object" alt="remove object" @click="removeObject" src="@/assets/toolbar/trash.svg" />
      </div>

      <!-- <canvas ref="designer" id="designer" width="700" height="1250"></canvas> -->
      <canvas ref="designer" id="designer" width="390" height="665"></canvas>
    </div>

    <IconSearchModal v-if="showImageModal" @add-icon="addIcon" @close="() => (showImageModal = false)" />

    <div class="bottom-menu mobile-only">
      <input
        type="text"
        :value="activeObj.text"
        v-on:keyup="e => updateText(e.target.value)"
        v-if="activeObj && activeObj.type === 'textbox'"
      />
      <div @click="addText">
        <img src="@/assets/text-icon.svg" />
      </div>
      <div @click="setShowImageModal">
        <img src="@/assets/icon-icon.svg" />
      </div>
      <div @click="activateMenu('wood')">
        <img src="@/assets/icon-wood.svg" />
      </div>
      <!-- <button @click="payWithStripeCheckout">Add <br />to Cart &gt;</button> -->
      <button class="sold-out" @click="checkout">Sold Out</button>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric';
import guidelines from '@/lib/guidelines';
import DesignMixin from './DesignMixin';
import '@/lib/history';
import fonts from '@/designer-fonts.json';
import axios from 'axios';
// import checkoutUtils from '@/utils/checkout';

import IconSearchModal from '@/components/IconSearchModal';

export default {
  name: 'DesignNew',
  components: { IconSearchModal },
  mixins: [DesignMixin],
  data() {
    return {
      studio: null,
      showFontDropdown: false,
      wood_type: 'maple',
      showImageModal: false,
      activeObj: null,
      fonts,
    };
  },
  async mounted() {
    this.init();

    // mouseflow tagging
    if (window._mfq) {
      window._mfq.push(['tag', 'saw-design']);
    }
  },
  methods: {
    async init() {
      if (!this.$store.state.fontsLoaded) {
        setTimeout(this.init, 500);
        return;
      }

      let canvas = new fabric.Canvas('designer', {
        selection: true,
        selectionBorderColor: 'black',
        backgroundColor: null,
        preserveObjectStacking: true,
        centeredScaling: true,
      });

      // resizing for mobile
      // let scaleX = window.outerWidth / 390;
      // let scaleY = (window.outerHeight - 200) / 665;
      // console.log(`scale x ${scaleX} - ${scaleY}`);
      // canvas.setZoom(scaleY);
      // canvas.renderAll();

      this.studio = canvas;
      window.canvas = canvas;
      guidelines(canvas);
      canvas.historyInit();

      this.studio.on('selection:created', o => {
        this.activeObj = o.target;
        this.activeObj.set({ borderColor: 'black', cornerColor: 'black' });
      });

      this.studio.on('selection:updated', o => {
        this.activeObj = o.target;
        this.activeObj.set({ borderColor: 'black', cornerColor: 'black' });
      });

      this.studio.on('selection:cleared', () => {
        this.activeObj = null;
      });

      /** Snap objects to center while dragging */
      this.studio.on('object:moving', o => {
        let studioCenter = this.studio.width / 2;
        let objCenter = o.target.left + o.target.getScaledWidth() / 2;

        let diff = Math.abs(studioCenter - objCenter);

        if (diff < 20) {
          o.target
            .set({
              left: studioCenter - o.target.getScaledWidth() / 2,
            })
            .setCoords();
        }
      });

      setInterval(async () => {
        this.activeObject = this.studio.getActiveObject();

        if (!this.activeObj || this.activeObj.type !== 'textbox') {
          this.showFontDropdown = false;
        }
      }, 250);

      if (this.$store.state.design && this.$store.state.design.fabric_data) {
        canvas.loadFromJSON(this.$store.state.design.fabric_data, () => {
          canvas.clearHistory();
        });
      } else if (!this.$route.query.id || this.$route.query.id === 'new') {
        fabric.Image.fromURL('/img/wifi-icon.png', async myImg => {
          this.studio.add(
            myImg.set({
              top: 439,
              left: 124.25,
            }),
          );

          this.addText();
          canvas.clearHistory();
        });
      } else {
        let templateResp = await axios.get(`/api/templates/${this.$route.query.id}`);
        let template = templateResp.data;

        if (template.wood_type) {
          this.wood_type = template.wood_type;
        } else {
          this.wood_type = 'maple';
        }

        canvas.loadFromJSON(template.fabric_data, () => {
          canvas.clearHistory();
        });
      }

      this.updateWood(this.wood_type);

      // let height = window.outerHeight - 200;
      // console.log('height: ' + height);

      // document.getElementsByClassName('.canvas-container')[0].style['max-height'] = `${height}px`;
      // document.getElementsByClassName('.lower-canvas')[0].style['max-height'] = `${height}px`;
      // document.getElementsByClassName('.outer-canvas')[0].style['max-height'] = `${height}px`;
    },
    updateText(val) {
      this.studio.getActiveObject().text = val;
      this.studio.renderAll();
    },
    async save() {
      this.studio.discardActiveObject().renderAll();

      let data = this.studio.toJSON();
      // localStorage.setItem('design', JSON.stringify(data));
      await this.$store.dispatch('updateDesign', { fabric_data: data, wood_type: this.wood_type });
      await this.$store.dispatch('setPreviewImage', this.$refs.designer.toDataURL());
    },
    async checkout() {
      await this.save();

      // checkoutUtils.payWithStripeCheckout();
    },
    align(direction) {
      this.studio.getActiveObject().set('textAlign', direction);
      this.studio.renderAll();
    },
    fontFamily(font) {
      this.studio.getActiveObject().set('fontFamily', font.family);
      this.studio.getActiveObject().set('fontWeight', font.weight);

      this.studio.renderAll();
    },
    textSize(direction) {
      let size = this.studio.getActiveObject().fontSize;

      if (direction === 'up') {
        size += 4;
      } else {
        size += -4;
      }

      this.studio.getActiveObject().set('fontSize', size);
      this.studio.renderAll();
    },
    removeObject() {
      this.studio.remove(this.studio.getActiveObject());
    },
    updateWood(type) {
      this.wood_type = type;

      let bkg = require(`@/assets/${this.wood_type}-mask.png`);
      this.studio.setBackgroundImage(bkg, this.studio.renderAll.bind(this.studio), {
        backgroundImageStretch: false,
      });
    },
    addText() {
      var text1 = new fabric.Textbox('Edit This Text', {
        top: 50,
        left: 95,
        width: 200,
        fontFamily: 'Acme',
        color: 'black',
        textAlign: 'center',
      });
      this.studio.add(text1);
      this.studio.renderAll();
    },
    toggleFontDropdown() {
      if (this.activeObj && this.activeObj.type === 'textbox') {
        this.showFontDropdown = !this.showFontDropdown;
      }
    },
    setShowImageModal() {
      this.showImageModal = !this.showImageModal;
    },
    addIcon(url) {
      this.showImageModal = false;

      fabric.loadSVGFromURL(url, (objects, options) => {
        var obj = fabric.util.groupSVGElements(objects, options);
        this.studio.add(obj).renderAll();
      });
    },
    undo() {
      this.studio.undo();
    },
    redo() {
      this.studio.redo();
    },
  },
};
</script>

<style lang="scss" scoped>
.designer-container {
  display: flex;
  flex-direction: row-reverse;
  min-height: 100%;
  max-height: 100%;
  box-sizing: border-box;

  svg {
    border: 1px solid black;
  }

  .left {
    border-right: 1px solid white;
    width: 500px;
    background-color: $tuatara;
    padding-top: 40px;
    color: white;

    .swatches {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 10px;
      margin: 30px 0;

      .swatch {
        cursor: pointer;

        img {
          width: 64px;
          height: 62px;
        }
      }
    }

    button {
      height: auto;
      padding: 5px 20px;
      font-size: 18px;

      &.sold-out {
        opacity: 0.5;
      }
    }

    .button-group {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      button {
        margin: 0 5px;
      }
    }
  }

  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url('~@/assets/marble-bkg.jpg');
    background-size: cover;
    background-position: top center;
    padding-top: 40px;

    .top-toolbar {
      background-color: $tuatara;
      width: 400px;
      height: 40px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 5px;
      position: relative;

      .button-group {
        display: flex;
        height: 30px;
        margin: 0 5px;
      }

      img,
      button {
        max-height: 20px;
        background-color: white;
        padding: 5px;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 2px;
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
        max-width: 30px;
        max-height: 30px;
        box-sizing: border-box;

        &:active {
          box-shadow: #807d7d 1px 1px 1px 1px;
          filter: opacity(0.75);
        }
      }

      button {
        &.inactive {
          background-color: black;
        }
      }

      .font-container {
        background-color: white;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: black;
        padding: 0 5px;
        height: 30px;
        border-radius: 4px;
        cursor: pointer;
        opacity: 0.4;
        width: 100px;

        &.enabled {
          opacity: 1;
        }

        .font-dropdown {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr 1fr;
          background-color: white;
          top: 100%;
          width: 400px;
          max-width: 100%;
          z-index: 100;
          left: 0;
          border-radius: 8px;
          font-size: 32px;
          text-align: center;

          div {
            padding: 10px 10px;

            &:hover {
              background-color: lightgray;
            }
          }
        }
      }
    }
  }

  .bottom-menu {
    display: none;

    input {
      position: absolute;
      top: -49px;
      background-color: white;
      color: black;
      width: 100%;
      border-radius: 0;
      border-top: 1px solid black;
    }
  }

  @include responsive(mobile) {
    .left {
      display: none;
    }
    .right {
      padding-top: 0;
      max-height: calc(100% - 77px);
      overflow: hidden;

      .top-toolbar {
        width: 100%;
        border-radius: 0;
        box-sizing: border-box;

        .font-container {
          flex-grow: 1;
        }
      }
    }

    .bottom-menu {
      background-color: #3a3a39;
      position: fixed;
      color: white;
      height: 83px;
      z-index: 200;
      width: 100%;
      // position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      transition: all 2s ease-out;

      div {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      button {
        height: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        font-size: 16px;
        padding: 0 15px;
        line-height: 24px;

        &.sold-out {
          opacity: 0.5;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@include responsive(mobile) {
  .canvas-container {
    align-self: flex-start;
    max-width: 100vw !important;
    max-height: calc(100vh - 260px) !important;
    margin: 0 auto;

    & {
      max-width: 100vw !important;
    }

    canvas {
      width: auto !important;
      max-height: calc(100vh - 260px) !important;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}
</style>
