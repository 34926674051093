<template>
  <div class="how-it-works-scene">
    <div class="how-it-works-scene-inner">
      <div class="main-row">
        <div></div>
        <div>How it works</div>
      </div>

      <div class="main-row">
        <div class="minor-container">
          <img src="@/assets/maple-tree.svg" loading="lazy" />
        </div>
        <div class="column major-container">
          <div class="align-left title">1. Choose your wood species</div>
          <div class="row block-container">
            <div class="column">
              <img src="@/assets/wifi-block-oak.png" loading="lazy" />
              <button class="btn-small" @click="gotoDesign">Oak</button>
            </div>
            <div class="column">
              <img src="@/assets/wifi-block-maple.png" loading="lazy" />
              <button class="btn-small" @click="gotoDesign">Maple</button>
            </div>
            <div class="column">
              <img src="@/assets/wifi-block-poplar.png" loading="lazy" />
              <button class="btn-small" @click="gotoDesign">Poplar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="main-row">
        <div class="major-container">
          <div class="title">2. Customize your wifi block</div>
          <div class="column">
            <img class="customization-image" src="@/assets/customization-options.svg" loading="lazy" />
            <button class="btn-small" @click="gotoDesign">Start Personalizing</button>
          </div>
        </div>

        <div class="minor-container">
          <img src="@/assets/oak-tree.svg" loading="lazy" />
        </div>
      </div>

      <div class="main-row delivery-row">
        <img src="@/assets/delivery-truck.svg" loading="lazy" />
        <div>Your Wifi Block Arrives!</div>
      </div>

      <div class="main-row">
        <div class="minor-container">
          <img src="@/assets/poplar-tree.svg" loading="lazy" />
        </div>

        <div class="major-container">
          <div class="title">1. Setup Your Wifi Block with an easy mobile app</div>
          <div class="row">
            <div class="space-right">
              <img src="@/assets/android-logo.svg" />
              <div>Android</div>
            </div>
            <div>
              <img src="@/assets/apple-logo.svg" />
              <div>Apple</div>
            </div>
          </div>
          <div class="disclaimer">
            * device must have nfc capability and be connected to the desired wifi for programming
          </div>
        </div>
      </div>

      <div class="main-row closing-text">
        That’s it! Next time someone wants to connect to your wifi, just tell them to tap their phone to your wifi
        block!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks',
  methods: {
    gotoDesign() {
      this.$router.push({ name: 'design' });
    },
  },
};
</script>

<style lang="scss" scoped>
.how-it-works-scene {
  background-color: #cbcdcc;
  width: 100%;
  font-weight: bold;
  font-size: 28px;
  color: #000000;

  .how-it-works-scene-inner {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px;

    img {
      max-width: 100%;
    }

    .main-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      padding: 50px 0;

      &:nth-child(1) {
        height: 150px;
        text-align: center;
      }

      .title {
        margin-bottom: 50px;
      }

      .block-container {
        width: 60%;

        .column {
          margin-right: 50px;
        }
      }

      .major-container {
        width: 50%;
      }

      .minor-container {
        width: 40%;
        align-self: center;

        img {
          max-height: 300px;
        }
      }

      button {
        margin-top: 20px;
      }

      .customization-image {
        margin-bottom: 30px;
      }
    }

    .delivery-row {
      border-top: 5px solid #f1aa49;
      border-bottom: 5px solid #f1aa49;
      margin: 0 10%;
      box-sizing: border-box;
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      img {
        margin-right: 100px;
      }
    }

    .closing-text {
      max-width: 850px;
      margin: 0 auto;
    }
  }

  @include responsive(mobile) {
    .block-container {
      flex-direction: column;
    }

    .delivery-row {
      flex-direction: column;
    }
  }

  .space-right {
    margin-right: 100px;
  }

  .disclaimer {
    font-size: 16px;
    font-weight: 300;
    margin-top: 40px;
  }
}
</style>
