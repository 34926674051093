<template>
  <div id="app">
    <DebugBar v-if="$store.state.debugMode" />
    <LoginModal v-if="$store.getters.showLoginModal" />
    <Header v-if="$route.meta.showDesktopHeader" />
    <MobileHeader v-if="$route.meta.showMobileHeader" />

    <transition name="fade">
      <router-view />
    </transition>

    <transition name="fade" appear>
      <div v-if="$store.state.fullpageLoader" class="fullpage-loader">
        <div class="wifi-symbol">
          <div class="wifi-circle first"></div>
          <div class="wifi-circle second"></div>
          <div class="wifi-circle third"></div>
          <div class="wifi-circle fourth"></div>
        </div>
      </div>
    </transition>

    <div v-if="$route.meta.footer" class="footer">
      <div>&copy; {{ year }} JSG Technology LLC</div>
      <div>All rights reserved</div>
      <div><router-link to="privacy">Privacy Policy</router-link></div>
    </div>
  </div>
</template>

<script>
import WebFontLoader from 'webfontloader';
import Fingerprint2 from 'fingerprintjs2';

import LoginModal from '@/components/LoginModal';
import DebugBar from '@/components/DebugBar';
import Header from '@/components/Header';
import MobileHeader from '@/components/MobileHeader';

import auth from '@/utils/auth';

export default {
  name: 'App',
  components: { LoginModal, DebugBar, Header, MobileHeader },
  created() {
    auth.googleAuthInit();

    if (this.$route.query.debugMode) {
      this.$store.dispatch('setDebugMode', true);
    }
  },
  mounted() {
    this.getFingerprint();

    this.$store.dispatch('loadTemplates');

    // Populate design if it exists
    let design = localStorage.getItem('design');
    if (design && design !== '') {
      let cachedDesign = JSON.parse(design);

      // ensure design is valid
      if (!cachedDesign._id) {
        localStorage.removeItem('design');
        return;
      }

      this.$store.dispatch('setDesign', cachedDesign);
    }

    // Hooks for window
    window.onresize = () => {
      this.$store.dispatch('updateLayout');
    };

    WebFontLoader.load({
      google: {
        families: [
          'Acme',
          'Courier Prime',
          'Crafty Girls',
          'Dancing Script',
          'Holtwood One SC',
          'Kotta One',
          'PT Serif',
          'Seaweed Script',
          'Smokum',
        ],
      },
      custom: {
        families: ['flanellaregular', 'frutilla_scriptregular'],
        urls: ['/fonts/flanella/stylesheet.css', '/fonts/frutilla/stylesheet.css'],
      },
      active: () => this.$store.dispatch('setFontsLoaded', true),
    });
  },
  methods: {
    getFingerprint() {
      // FingerprintJS 2
      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          Fingerprint2.get(components => {
            var values = components.map(function(component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(''), 31);
            this.$store.dispatch('setVisitorId', murmur);
          });
        });
      } else {
        setTimeout(() => {
          Fingerprint2.get(components => {
            var values = components.map(function(component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(''), 31);
            this.$store.dispatch('setVisitorId', murmur);
          });
        }, 500);
      }
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@/_global.scss';

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
  }
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.footer {
  margin-bottom: 20px;
  color: $tuatara;
  font-size: 12px;

  a,
  a:visited,
  a:active {
    color: $tuatara;
    text-decoration: underline;
    font-family: 'Poppins', sans-serif;
  }
}

.fullpage-loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);

  .wifi-symbol {
    display: none;

    $circleColor: #ffffff;
    $size: 150px;

    [foo],
    & {
      display: block;
      position: absolute;
      top: 50%;
      display: inline-block;
      width: $size;
      height: $size;
      margin-top: 0 - $size - $size * 0.25;

      -ms-transform: rotate(-45deg) translate(-100px);
      -moz-transform: rotate(-45deg) translate(-100px);
      -o-transform: rotate(-45deg) translate(-100px);
      -webkit-transform: rotate(-45deg) translate(-100px);
      transform: rotate(-45deg) translate(-100px);
    }
    .wifi-circle {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      font-size: $size/7;
      position: absolute;
      bottom: 0;
      left: 0;
      border-color: $circleColor;
      border-style: solid;
      border-width: 1em 1em 0 0;
      -webkit-border-radius: 0 100% 0 0;
      border-radius: 0 100% 0 0;

      opacity: 0;
      -o-animation: wifianimation 3s infinite;
      -moz-animation: wifianimation 3s infinite;
      -webkit-animation: wifianimation 3s infinite;
      animation: wifianimation 3s infinite;

      &.first {
        -o-animation-delay: 800ms;
        -moz-animation-delay: 800ms;
        -webkit-animation-delay: 800ms;
        animation-delay: 800ms;
      }
      &.second {
        width: 5em;
        height: 5em;
        -o-animation-delay: 400ms;
        -moz-animation-delay: 400ms;
        -webkit-animation-delay: 400ms;
        animation-delay: 400ms;
      }
      &.third {
        width: 3em;
        height: 3em;
      }
      &.fourth {
        width: 1em;
        height: 1em;
        opacity: 1;
        background-color: $circleColor;
        -o-animation: none;
        -moz-animation: none;
        -webkit-animation: none;
        animation: none;
      }
    }
  }

  @-o-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
  @-moz-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
  @-webkit-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
}

// .mobile-only {
//   display: none !important;

//   @media only screen and (max-width: 1000px) {
//     display: block;
//   }
// }
.desktop-only {
  display: block;

  @media only screen and (max-width: 1000px) {
    display: none !important;
  }
}
</style>
