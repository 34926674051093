// admin module for the store
let axios = require('axios');
import moment from 'moment';
import router from '@/router';
import config from '@/utils/config';

function groupByCreated(orders) {
  // let earliestDate = null;

  let summary = orders.reduce((a, e) => {
    let dt = new moment(e.created).format('MM/DD');
    if (!a[dt]) {
      a[dt] = 1;
    } else {
      a[dt] = a[dt] + 1;
    }

    return a;
  }, {});

  // if (!earliestDate || e.created < earliestDate) {
  //   earliestDate = moment(e.created);
  // }

  // while (earliestDate < moment.now()) {
  //   let dt = new moment(earliestDate).format('MM/DD');

  //   if (!summary[dt]) {
  //     summary[dt] = 0;
  //   }
  // }

  return summary;
}

export default {
  namespaced: true,
  state: {
    orders: [],
    designs: [],
    templates: [],
    orderSummary: {},
    designSummary: {},
    balance: {},
  },
  mutations: {
    setOrders(state, val) {
      state.orders = val;
      state.orderSummary = groupByCreated(val);
    },
    setDesigns(state, val) {
      state.designs = val;
      state.designSummary = groupByCreated(val);
    },
    setTemplates(state, val) {
      state.templates = val;
    },
    updateOrder(state, order) {
      let index = state.orders.findIndex(e => e._id === order._id);
      state.orders.splice(index, 1, order);
    },
    setBalance(state, bal) {
      state.balance = bal;
    },
  },
  actions: {
    setInitialLoad: ({ commit }) => {
      // axios.get(`${process.env.VUE_APP_BACKEND_HOST}/admin/designs`).then(resp => {
      //   commit('setDesigns', resp.data);
      // });

      axios
        .get(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/orders`)
        .then(resp => {
          let orders = resp.data.sort((a, b) => {
            return new Date(a.created) < new Date(b.created) ? 1 : -1;
          });
          commit('setOrders', orders);
        })
        .catch(() => {
          router.push('/login');
        });

      axios
        .get(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/balance`)
        .then(resp => {
          commit('setBalance', resp.data);
        })
        .catch(() => router.push('/login'));

      axios
        .get(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/templates`)
        .then(resp => {
          commit('setTemplates', resp.data);
        })
        .catch(() => router.push('/login'));
    },
    updateOrder: ({ commit }, order) => {
      commit('updateOrder', order);
    },
    getFullOrder: ({ commit }, id) => {
      axios
        .get(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/order/${id}`)
        .then(resp => {
          commit('updateOrder', resp.data);
        })
        .catch(() => router.push('/login'));
    },
    updateTemplate: ({ commit, state }, template) => {
      let templates = state.templates;

      let index = templates.findIndex(e => {
        if (e._id === template._id) {
          return true;
        }
      });

      if (index >= 0) {
        templates.splice(index, 1, template);

        commit('setTemplates', templates);
      }
    },
  },
  getters: {
    designs(state) {
      return state.designs;
    },
    orders(state) {
      return type => (!type ? state.orders : state.orders.filter(e => e.status === type));
    },
    order(state) {
      return id => state.orders.filter(e => e._id === id)[0] || {};
    },
    orderSummary(state) {
      return state.orderSummary;
    },
    designSummary(state) {
      return state.designSummary;
    },
    balance(state) {
      return {
        ...state.balance,
        gross: Math.round(state.balance.gross),
      };
    },
  },
};
